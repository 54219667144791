import React from 'react';
import styles from './index.module.css'

const BlockRight = (props) => {
  const { json } = props
  return (
    <div className={styles.blockRight_mobile}>
      <div className={styles.blockRight_left}>
        <div className={styles.blockRight_title}>{json.title}</div>
        <div className={styles.blockRight_titleBox}>
          {json.descList.map((text) => {
            return (<div className={styles.blockRight_desc}>
              {text}
            </div>)
          })}
        </div>
        <div className={styles.blockRight_logoBox}>
          {json.iconList.map((text) => {
            return (<div className={styles.blockRight_titleLogo}>
              <img src={text} alt="" 
              />
            </div>)
          })}
        </div>
      </div>
      <div className={styles.blockRight_img}>
        <img
          src={
            json.imgRight
          }
          alt=""
          
        />
      </div>
      <div className={styles.blockRight_imgLogo}>
        <img
          src={
            json.imgLogoRight
          }
          alt=""
        />
      </div>
    </div>
  )
}

export default BlockRight;