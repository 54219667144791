import React from 'react';
import styles from './index.module.css'

const BlockLeft = (props) => {
  const { json } = props
  return (
    <div className={styles.blockLeft_mobile}>
      <div className={styles.blockLeft_img}>
        <img
          src={
            json.imgLeft
          }
          alt=""
        />
      </div>
      <div className={styles.blockLeft_imgLogo}>
        <img
          src={
            json.imgLogoLeft
          }
          alt=""
         
        />
      </div>
      <div className={styles.blockLeft_right}>
        <div className={styles.blockLeft_title}>{json.title}</div>
        <div className={styles.blockLeft_titleBox}>
          {json.descList.map((text) => {
            return (<div className={styles.blockLeft_describe}>
              {text}
            </div>)
          })}
        </div>
        <div className={styles.blockLeft_logoBox}>
          {json.iconList.map((text) => {
            return (<div className={styles.blockLeft_titleLogo}>
              <img src={text} alt="" 
              />
            </div>)
          })}
        </div>
      </div>
    </div>
  )
}

export default BlockLeft;