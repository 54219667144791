import React from "react"
import Layout from "../components-mobile/layout"
import Banner from "../components-mobile/home/banner"
import BlockLeft from "../components-mobile/smart-life/block-left"
import BlockRight from "../components-mobile/smart-life/block-right"
import Steward from "../components-mobile/smart-life/steward"


const Lifemobile = () => {
  const bannerJson = {
    title: '智慧生活',
    subTitle: '一部手机，开启高品质社区生活体验',
    bannerUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-13/1fce89a998056d87cfa65cf81e0431a8',
    imgUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-13/116341f476da84a362ea051e885b8f91',
    iconUrl: 'http://s.lianmeihu.com/x-uploader/image/2021-07-13/cf8ce89d5e06db8a77e3f779dc9b8c6b'
  }

  const parkJson = {
    imgLeft: 'http://s.lianmeihu.com/x-uploader/image/2021-07-13/e0cc0eb60a3f150f2714f8606846252d',
    imgLogoLeft: 'http://s.lianmeihu.com/x-uploader/image/2021-07-13/adeaa2c538d8b4708931c876dae263bc',
    title: '智慧停车',
    descList: ['业主车牌自动识别，快速过闸','外来车辆进库智慧分流','业主车牌自动识别，快速过闸'],
    iconList: ['http://s.lianmeihu.com/x-uploader/image/2021-07-13/1d2bf5de06af33fbbdb32568831132f5',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-13/f13a137c5bc2612528a7963a23898844',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-13/619979bb5f87a23de94a87ce7a5c7c0b']
  }
  const currentJson = {
    imgRight: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/ee8aefbaf347ede42322f27c67af1572',
    imgLogoRight: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/d6d2de4417525cdeb55ace920da9beb3',
    title: '社区通行',
    descList: ['业主通过人脸识别、APP扫码或NFC功能，快速进出社区、单元楼','访客通过业主远程开门或扫描业主授权动态码进出社区、单元楼'],
    iconList: ['http://s.lianmeihu.com/x-uploader/image/2021-07-15/c65255a60af52752278b91d66f357ba5',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/b4184d4435a5dba20adc0e76222b5ada',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/e0202a30ca4aadf4d811b6fbeca40433',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/71603cb7e0263857e02c977de6c7fd68']
  }

  const squareJson = {
    imgRight: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/969fa4b8822a851f05759d986f4ce0fc',
    imgLogoRight: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/18668b5a6255c075c1f1fd1f3f247850',
    title: '服务广场',
    descList: ['餐饮、衣护、保洁、宠物、育儿、娱乐，周边服务个性化推荐','在线查询、优惠专享、一键下单、服务到家'],
    iconList: ['http://s.lianmeihu.com/x-uploader/image/2021-07-15/5cbf7a139a279c78926ce1caeb41985a',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/a3aeda35514a1575a7082d2b42a0d887',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/38116433a747f41e75893d091fdfcbe5',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/b3ff73eaee65de68c7f7bc55592eebc6']
  }

  const mallJson = {
    imgLeft: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/58cb74becc441b6ac50d66b4339707b5',
    imgLogoLeft: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/68d4dd06d69512f1b5fb47856d1cd21c',
    title: '社区商城',
    descList: ['在线社区商城，一键下单','社区仓库实现当日送达'],
    iconList: ['http://s.lianmeihu.com/x-uploader/image/2021-07-15/b0fa1df1b12847f9d1d24323c591b450',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/ab102d0540af53fd75aea07893d753b6',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/a2facac9982d6ef2b0eeddd1ded39f52']
  }

  const neighborhoodJson = {
    imgRight: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/8dc0d8ec31a46240703bba211b0fa8d8',
    imgLogoRight: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/75609be02ae447d6b32d1d9bc474b301',
    title: '邻里社交',
    descList: ['手机APP公共议题讨论、交友互动','邻里互助、闲置交换等社交活动，打破社区人际隔阂'],
    iconList: ['http://s.lianmeihu.com/x-uploader/image/2021-07-15/dd8feb78786f242a10badb73eaf6ef10',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/9cf8ee2a3c79d8bc85ae1767dd048f40',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/d9a43c4ef9e5c428ae311022ea71a19e']
  }

  const workJson = {
    imgLeft: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/2b72bc612df8c61ae6e5656eea5b3bb5',
    imgLogoLeft: 'http://s.lianmeihu.com/x-uploader/image/2021-07-15/df5f48d6f9862401b957305ce3cbf84e',
    title: '办事直通车',
    descList: ['政府资讯及时推送','政府政策快速查询'],
    iconList: ['http://s.lianmeihu.com/x-uploader/image/2021-07-15/985ffffbe16f2448ecf1dd0e13e98512',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/9174c79dac7eb426c4259650d8f9ee6c',
              'http://s.lianmeihu.com/x-uploader/image/2021-07-15/9bb417cde193018ae00f368f68a2c1f2']
  }

  return (
    <Layout page="Life">
      <Banner json={bannerJson} />
  
      <BlockLeft json={parkJson} />
      <BlockRight json ={currentJson}/>
      <Steward/>
      <BlockRight json={squareJson}/>
      <BlockLeft json={mallJson}/>
      <BlockRight json={neighborhoodJson}/>
      <BlockLeft json={workJson}/>
    </Layout>
  )
}

export default Lifemobile