import React from 'react'
import styles from './index.module.css'

const Steward = (props) => {
  // const { json } = props
  return (
    <div className={styles.steward_mobile}>
      <div className={styles.steward_img1}>
        <img
          src="http://s.lianmeihu.com/x-uploader/image/2021-07-15/2a2ebaf3a86431ed11a60f13620cf480"
          alt=""
  
        />
      </div>
      <div className={styles.steward_img2}>
        <img
          src="http://s.lianmeihu.com/x-uploader/image/2021-07-15/1b51a4560bdae3f5957ebd6a3e900cbf"
          alt=""
        />
      </div>
      <div className={styles.steward_imgLogo}>
        <img
          src="http://s.lianmeihu.com/x-uploader/image/2021-07-15/fb0ed1519b5cf3d82077ef2a8c14fa28"
          alt=""
      
        />
      </div>
      <div className={styles.steward_right}>
        <div className={styles.steward_title}>物业管家</div>
        <div className={styles.steward_titleBox}>
          <div className={styles.steward_describe}>
            线上报修，工单进度实时可查
          </div>
          <div className={styles.steward_describe}>
            物业通知，APP端及时推送
          </div>
          <div className={styles.steward_describe}>
            在线缴费，账目清晰、免上门
          </div>
        </div>
        <div className={styles.steward_logoBox}>
          <div className={styles.steward_titleLogo}>
            <img src="http://s.lianmeihu.com/x-uploader/image/2021-07-15/25d4f08223e0e2d3ca4be7aa19a0845d"
              alt=""
               />
          </div>
          <div className={styles.steward_titleLogo}>
            <img src="http://s.lianmeihu.com/x-uploader/image/2021-07-15/151d1832a96de89a5e707907531e143f"
              alt=""
               />
          </div>
          <div className={styles.steward_titleLogo}>
            <img src="http://s.lianmeihu.com/x-uploader/image/2021-07-15/223013140359e0aac32fe91f2b7cb4ae"
              alt=""
               />
          </div>
          <div className={styles.steward_titleLogo}>
            <img src="http://s.lianmeihu.com/x-uploader/image/2021-07-15/a3846afb9ca768c56b1ffe188056fc6f"
              alt=""
               />
          </div>

        </div>
      </div>
    </div>
  )
}

export default Steward;